import React from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"
import { getCurrentLangKey } from 'ptz-i18n';

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from '../components/Events/HeaderSection/HeaderSection'
import Section from '../components/Events/Section/EventSection'
import LinkedArticles from '../components/Article/LinkedArticles/LinkedArticles'

const Events = props => {
  
    const url = props.location.pathname;
    
    const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
   
    let langKey = getCurrentLangKey(langs, defaultLangKey, url);
    let homeLink = `/${langKey}/`

    const seoData = get(props, 'data.pageData.nodes[0].seo',[])
    const pageData = get(props, 'data.pageData.nodes[0]', [])

    let data = {
        title: seoData.title,
        description: seoData.description,
        lang: 'en',
        image: seoData.social.facebook.image && seoData.social.facebook.image.url,
        imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
        robots: seoData.advanced.robots && seoData.advanced.robots
    }
  
    // slugs for translation
    const slugs = {
      slugNl: props.data.translatedUrl.nodes[0].slug,
      slugEn: props.data.pageData.nodes[0].slug,
    }
  
    return(
        <Layout form={false} data={props.data.site} location={props.location} slugs={slugs}>
            <SEO {...data} />
            <HeaderSection url={homeLink} pageData={pageData}/>
            <Section data={pageData}/>
            <LinkedArticles linkedArticles={pageData.linkedArticles} location={props.location} cols={5} />
        </Layout>
    )
}

export default Events

export const query = graphql`
  {
    site{
      siteMetadata{
        languages {
          defaultLangKey
          langs
        }
      }
    }
    pageData: allCraftEventsEventsEntry(filter: {siteId: {eq:2}}){
      nodes{
        slug
        language
        title
        eventTitle
        pageIntro
        seo {
          title
          description
          social {
            facebook {
              image {
                url
              }
            }
            twitter {
              image {
                url
              }
            }
          }
          advanced {
            robots
          }
        }
        eventTitle
          eventTimeTable {
            ... on craft_eventTimeTable_table_BlockType {
              id
              TimeTitle
              time
              paragrpah
            }
          }
          eventParagraph
          eventTime
          eventImageDesktop {
            url
          }
          eventImageMobile {
            url
          }
          eventDate
          eventAdres
          linkedArticles {
            ... on craft_article_article_Entry {
              id
              title
              blogintro
              slug
              blogreadtime
              dateCreated
              language
              postDate
              author {
                name
              }
              blogcoverimg {
                ... on craft_superkraft_Asset {
                  id
                  url
                }
              }
              categoriesServices {
                title
              }
            }
          }
      }
    }
    translatedUrl: allCraftEventsEventsEntry(filter: {siteId: {eq:2}}){
      nodes{
        slug
      }
    }
  }
`